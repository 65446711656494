exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-get-ephoria-tsx": () => import("./../../../src/pages/get-ephoria.tsx" /* webpackChunkName: "component---src-pages-get-ephoria-tsx" */),
  "component---src-pages-get-karma-tsx": () => import("./../../../src/pages/get-karma.tsx" /* webpackChunkName: "component---src-pages-get-karma-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-business-tsx": () => import("./../../../src/templates/business.tsx" /* webpackChunkName: "component---src-templates-business-tsx" */),
  "component---src-templates-checkin-tsx": () => import("./../../../src/templates/checkin.tsx" /* webpackChunkName: "component---src-templates-checkin-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-download-tsx": () => import("./../../../src/templates/download.tsx" /* webpackChunkName: "component---src-templates-download-tsx" */),
  "component---src-templates-helsana-tsx": () => import("./../../../src/templates/helsana.tsx" /* webpackChunkName: "component---src-templates-helsana-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-imprint-tsx": () => import("./../../../src/templates/imprint.tsx" /* webpackChunkName: "component---src-templates-imprint-tsx" */),
  "component---src-templates-privacy-tsx": () => import("./../../../src/templates/privacy.tsx" /* webpackChunkName: "component---src-templates-privacy-tsx" */),
  "component---src-templates-science-tsx": () => import("./../../../src/templates/science.tsx" /* webpackChunkName: "component---src-templates-science-tsx" */),
  "component---src-templates-success-tsx": () => import("./../../../src/templates/success.tsx" /* webpackChunkName: "component---src-templates-success-tsx" */),
  "component---src-templates-support-tsx": () => import("./../../../src/templates/support.tsx" /* webpackChunkName: "component---src-templates-support-tsx" */),
  "component---src-templates-team-tsx": () => import("./../../../src/templates/team.tsx" /* webpackChunkName: "component---src-templates-team-tsx" */),
  "component---src-templates-terms-tsx": () => import("./../../../src/templates/terms.tsx" /* webpackChunkName: "component---src-templates-terms-tsx" */)
}



import smoothScroll from "./src/lib/smoothScroll";

export const onRouteUpdate = ({ location, prevLocation }) => {
    //console.log('updated route:', location.pathname);

    let search = location.pathname.split('/').slice(0, 3).join('/');
    //console.log('search:', search);
    // replace blog with about
    search = search.replace('/blog', '/about');
    search = search.replace('/team', '/about');
    if(location.pathname.startsWith('/de')) search = search.replace('/support', '/kontakt');
    else search = search.replace('/support', '/contact');

    if(search.length === 4) search = search.substring(0, 3);

    search = decodeURIComponent(search);

    const nav = document.querySelector('#menuitems a[href="' + search + '/"]');
    if(nav) nav.classList.add('active');


    // check if there is an anchor in the url
    if(location.hash) {
        console.log('hash:', location.hash);
        const id = location.hash.substring(1);
        setTimeout(() => {
          smoothScroll(id);
        }, 100);
    }


    if(location.search.includes('?nostats')) return;

    // skip web stats in development
    if(process.env.NODE_ENV !== 'production') return;
    // if query string contains 'no-stats' then skip

    // use slight delay to ensure that the page title has been set
    setTimeout(() => {
        var _paq = window._paq = window._paq || [];
      _paq.push(['setCustomUrl', window.location.pathname]);
      _paq.push(['setDocumentTitle', document.title]);
      //_paq.push(['setDocumentTitle', '']); // TODO: fix this, need current title
      _paq.push(['requireCookieConsent']);
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);

      (function() {
        var u="https://ephoria.matomo.cloud/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '1']);

        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://cdn.matomo.cloud/ephoria.matomo.cloud/matomo.js';
        s.parentNode.insertBefore(g,s);
        //console.log('title->',document.title);
      })();
    }, 100);

  }
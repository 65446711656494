import { gsap } from "gsap";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

const smoothScroll = (id: string, duration: number = 3, ease: string = 'power4.out') => {
    // smooth scroll to #demo
    const el = document.getElementById(id);
    // get screen width
    const screenWidth = window.innerWidth;
    const offset = screenWidth <= 768 ? 0 : -100; // floating navigation height on desktop
    if (el) {
        gsap.to(window, {duration, scrollTo: {y: el.offsetTop + offset, offsetY: 0}, ease});
    }
};

export default smoothScroll;